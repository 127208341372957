.menu {
  transition: transform 0.25s;
  transform: translateX(-110%);
}

.menu[aria-expanded="true"] {
  transform: translateX(0%);
}

@media (min-width: 640px) {
  .menu {
    display: static;
    transform: none;
  }
}
